import axios from "axios";
import { Button } from "react-bootstrap";
import { AppId, rootUrl } from "../constants";
import { db } from "./db";
import { NavLink } from "react-router-dom";

class Network {

    onPreConnect;
    onPostConnect;
    onConnectionFailed;
    onConnectionSucceed;
    onMessageReceived;

    url;
    params;
    method;
    context;
    dialogueContext;
    cashable;
    deliable;
    title;
    baseURL;
    paramsCopy;

    abortController;

    constructor({ url, method, context, dialogueContext, cashable = false, title, deliable = true, baseURL = rootUrl, onPreConnect = () => {
        this.context.showLoading();
        this.context.hideNoticeMessage()
    }, onPostConnect = () => {
        this.context.showLoading(false);
    }, onConnectionSucceed = (data, cashed) => { }, onConnectionFailed = () => {
        this.dialogueContext.editDialogue({
            title: "لا يتوفر اتصال بالشبكة",
            content: "لا يتوفر اتصال بالشبكة، تأكد من إعدادات الاتصال الخاصة بك ثم حاول مجدداً",
            children: [
                <Button onClick={e => {
                    this.dialogueContext.hideDialogue()
                    Network.instances[0].invoke()
                }}>المحاولة مجدداً</Button>
            ]
        })
    }, onMessageReceived = (message) => {
        console.log(message)
        this.context.showNoticeMessage(message)
    }, params = {} }) {
        this.onPreConnect = onPreConnect
        this.onPostConnect = onPostConnect
        this.onConnectionFailed = onConnectionFailed
        this.onConnectionSucceed = onConnectionSucceed
        this.onMessageReceived = onMessageReceived

        this.paramsCopy = params;
        this.abortController = new AbortController();
        params = new URLSearchParams();
        params.append('deviceInfo', 'admin');
        if (localStorage.getItem("admin") != null) params.append('adminToken', JSON.parse(localStorage.getItem("admin")).token + '');
        params.append('appId', AppId)
        for (let key of Object.keys(this.paramsCopy)) {
            let value = this.paramsCopy[key]
            params.append(key, value)
        }
        this.url = url;
        this.params = params;
        this.method = method;
        this.context = context;
        this.dialogueContext = dialogueContext;
        this.cashable = cashable;
        this.baseURL = baseURL;
        this.deliable = deliable
        this.title = title
        Network.instances.push(this)
    }

    static instances = []
    static delayedInstances = []

    finishInstance = (delayed = false) => {
        // if (delayed === true) {
        //     Network.delayedInstances.shift()
        //     if (Network.delayedInstances.length > 0) Network.delayedInstances[0].invokeDelayed()
        // }
        // else {
        Network.instances.shift()
        if (Network.instances.length > 0) Network.instances[0].invoke()
        // else if (Network.delayedInstances.length > 0) Network.delayedInstances[0].invokeDelayed()
        // }
    }

    invokeDelayed = async () => {
        if (Network.instances[0] != this) return;
        axios({
            method: this.method,
            signal: this.abortController.signal,
            url: this.url,
            baseURL: this.baseURL,
            data: this.params
        }).then(async response => {
            console.log(response)
            if (response.data.hasOwnProperty("result")) {
                if (response.data.result == 'failed') {
                    this.onMessageReceived(response.data.message)
                }
                else {
                    if (response.data.hasOwnProperty("successMessage")) {
                        this.context.showToast({
                            type: "success",
                            "title": response.data.successMessage.title,
                            "description": response.data.successMessage.description
                        })
                    }
                    if (this.cashable == true) {
                        let httpResponse = await db.httpRequests.get({
                            url: this.baseURL + this.url,
                            params: JSON.stringify(this.paramsCopy),
                            deliable: 0
                        })
                        if (httpResponse) {
                            db.httpRequests.update(httpResponse.id, {
                                response: response.data
                            })
                        }
                        else db.httpRequests.add({
                            url: this.baseURL + this.url,
                            params: JSON.stringify(this.paramsCopy),
                            response: response.data,
                            deliable: 0
                        })
                    }
                }
            }
            else {
                this.onMessageReceived(JSON.stringify(response.data))
            }
            this.finishInstance(true)
        }).catch(error => {
            if (error.hasOwnProperty("response")) {
                if (error.response.status == 500) {
                    this.onMessageReceived("حصل خطأ تقني، يرجى التواصل مع الدعم الفني")
                    return
                }
            }
            console.log(error)
            try {
                if (error.code == 'ERR_CANCELED');
                else {
                    this.finishInstance(true)
                }
            }
            catch (e) {
                console.log(e)
                this.onMessageReceived("حصل خطأ تقني، يرجى التواصل مع الدعم الفني")
                this.finishInstance(true)
            }
        })
    }

    invoke = async () => {
        if (Network.instances[0] != this) {
            if (Network.instances[0].cashable == false);//Network.instances[0].invoke()
            else {
                Network.instances.shift()
                Network.instances[0].abortController.abort()
                this.invoke()
            }
            return;
        };
        this.onPreConnect()
        if (this.cashable == true) {
            let response = await db.httpRequests.get({
                url: this.baseURL + this.url,
                params: JSON.stringify(this.paramsCopy),
                deliable: 0
            })
            if (response) {
                this.onConnectionSucceed(response.response, true)
            }
        }
        if (this.deliable === true) {//&& 1 === 0
            setTimeout(
                () => {
                    if (Network.instances[0] === this) this.dialogueContext.editDialogue({
                        tag: "long process",
                        title: "العملية تتطلب وقتاً طويلاً",
                        content: <div> هذا الإجراء يتطلب وقتاً أطول من المعتاد، هل تريد تأجيل هذا الإجراء؟
                            <p>يمكنك متابعة سير هذا الإجراء من زر <NavLink to='/delayedRequests'>العمليات المؤجلة</NavLink> في القائمة الجانبية</p>
                        </div>,
                        children: [
                            <Button onClick={e => {
                                db.httpRequests.add({
                                    url: this.baseURL + this.url,
                                    params: JSON.stringify(this.paramsCopy),
                                    deliable: 1,
                                    title: this.title
                                })
                                Network.delayedInstances.push(this)
                                this.dialogueContext.hideDialogue("long process")
                                this.onPostConnect()
                                Network.instances[0].abortController.abort()
                                this.finishInstance()
                            }}>تأجيل الإجراء</Button>,
                            <Button onClick={e => {
                                this.dialogueContext.hideDialogue("long process")
                                this.onPostConnect()
                                Network.instances[0].abortController.abort()
                                this.finishInstance()
                            }}>إيقاف الإجراء</Button>
                        ]
                    })
                }, 10000);
        }
        axios({
            method: this.method,
            signal: this.abortController.signal,
            url: this.url,
            baseURL: this.baseURL,
            data: this.params
        }).then(async response => {
            this.dialogueContext.hideDialogue("long process")
            this.onPostConnect()
            if (window.location.hostname === 'localhost') console.log(response)
            if (response.data.hasOwnProperty("result")) {
                if (response.data.result == 'failed') {
                    this.onMessageReceived(response.data.message)
                }
                else {
                    if (response.data.hasOwnProperty("successMessage")) {
                        this.context.showToast({
                            type: "success",
                            "title": response.data.successMessage.title,
                            "description": response.data.successMessage.description
                        })
                    }
                    this.onConnectionSucceed(response.data, false)
                    if (this.cashable == true) {
                        let httpResponse = await db.httpRequests.get({
                            url: this.baseURL + this.url,
                            params: JSON.stringify(this.paramsCopy),
                            deliable: 0
                        })
                        if (httpResponse) {
                            db.httpRequests.update(httpResponse.id, {
                                response: response.data
                            })
                        }
                        else db.httpRequests.add({
                            url: this.baseURL + this.url,
                            params: JSON.stringify(this.paramsCopy),
                            response: response.data,
                            deliable: 0
                        })
                    }
                }
            }
            else {
                this.onMessageReceived(JSON.stringify(response.data))
            }
            this.finishInstance()
        }).catch(error => {
            this.onPostConnect()
            if (error.hasOwnProperty("response")) {
                if (error.response.status == 500) {
                    this.onMessageReceived("حصل خطأ تقني، يرجى التواصل مع الدعم الفني")
                    return
                }
            }
            console.log(error)
            try {
                if (error.code == 'ERR_NETWORK') {
                    this.onConnectionFailed()
                }
                else if (error.code == 'ERR_CANCELED');
                else {
                    this.finishInstance()
                }
            }
            catch (e) {
                console.log(e)
                this.onMessageReceived("حصل خطأ تقني، يرجى التواصل مع الدعم الفني")
                this.finishInstance()
            }
        })
    }
}

export default Network